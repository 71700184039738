export interface TransportationDetails {
  id: string;
  transportationType: string;
  resourceName: string;
  confirmationNo: string;
  pickUpDate: Date;
  pickUpBeginDate: Date;
  pickUpBeginTime: string;
  pickUpLocation: string;
  pickUpAddress1: string;
  pickUpAddress2: string;
  pickUpAddress3: string;
  pickUpCity: string;
  pickUpState: string;
  pickUpZip: string;
  dropOffDate: Date;
  dropOffEndDate: Date;
  dropOffEndTime: string;
  dropOffLocation: string;
  dropOffAddress1: string;
  dropOffAddress2: string;
  dropOffAddress3: string;
  dropOffCity: string;
  dropOffState: string;
  dropOffZip: string;
  companyName: string;
}
